import { useMemo } from 'react'
import { useAuth } from '../../Contexts/AuthContext'
import { isPermissionAllowed } from '../helpers/getUserPermissions'

export default function useReadOnly(permissions) {
  const { currentAuthUser, getCurrentUser } = useAuth()
  const user = currentAuthUser || getCurrentUser()

  const isReadOnly = useMemo(() => {
    const permissionsArray = Array.isArray(permissions)
      ? permissions
      : [permissions]

    return permissionsArray.some(permission =>
      isPermissionAllowed({
        user,
        permission,
      })
    )
  }, [currentAuthUser, permissions])

  return { isReadOnly: isReadOnly }
}
