import React, { useState, useEffect } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useAddCollectionStore } from '../../../Storage/useAddCollectionStore'
import { useCollectionsStore } from '../../../Storage/useCollectionStore'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { useNavigate } from 'react-router-dom'
import { useAPI } from '../../../Contexts/APIContext'
import CollectionDropdownList from '../CollectionDropdownList/CollectionDropdownList'
import StatusButton from '../../GeneralComponents/StatusButton/StatusButton'
import FeelItemCard from '../FeelItemCard/FeelItemCard'
import FeelAddItem from '../FeelAddItem/FeelAddItem'
import StatusBadge from '../../GeneralComponents/StatusBadge/StatusBadge'
import CollectionNameInput from '../CollectionNameInput/CollectionNameInput'
import PropTypes from 'prop-types'
import classes from './index.module.css'
import { ButtonOutline } from '../../GeneralComponents/ButtonOutline/ButtonOutline'
import PublicationBadge from '../../GeneralComponents/PublicationBadge'

export default function CollectionAdd({ collectionLength }) {
  const feelsLimit = 250
  const isPublic = true
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { addCollection } = useAPI()

  // zustand storage
  const feels = useAddCollectionStore(state => state.feels)
  const setFeels = useAddCollectionStore(state => state.setFeels)
  const denySaving = useAddCollectionStore(state => state.denySaving)
  const setDenySaving = useAddCollectionStore(state => state.setDenySaving)
  const collectionName = useAddCollectionStore(state => state.collectionName)
  const setCollectionName = useAddCollectionStore(
    state => state.setCollectionName
  )
  const collectionLocation = useAddCollectionStore(
    state => state.collectionLocation
  )
  const setCollectionLocation = useAddCollectionStore(
    state => state.setCollectionLocation
  )

  const { feelsMuted, setFeelsMuted } = useCollectionsStore()
  const [isDragging, setIsDragging] = useState(false)

  // SAVING VALIDATION
  function getFeelsFIDs(feels) {
    const fIds = feels.map(feel => feel.f_id)
    return fIds
  }

  useEffect(() => {
    if (collectionName && collectionLocation !== null && feels.length) {
      setDenySaving(false)
    } else {
      setDenySaving(true)
    }
  }, [collectionName, collectionLocation, feels])

  const createCollectionMutation = useMutation({
    mutationFn: data =>
      addCollection(
        collectionName,
        isPublic,
        getFeelsFIDs(feels),
        collectionLocation
      ),
    onSuccess: data => {
      queryClient.invalidateQueries({ queryKey: ['collections'] })
      setCollectionName(null)
      setCollectionLocation(null)
      setFeels([])
    },
  })

  function onSaveCollection() {
    if (!denySaving) {
      createCollectionMutation.mutate()
    }
  }

  function handleClickOnFeel(feel) {
    navigate('/feel/' + feel.f_id)
  }

  function handleOnDragStart() {
    setIsDragging(true)
  }

  function handleOnDragEnd(result) {
    setIsDragging(false)
    if (!result.destination) {
      return
    }

    const items = Array.from(feels)
    const [reorderedItem] = items.splice(result.source.index, 1)
    items.splice(result.destination.index, 0, reorderedItem)

    setFeels(items)
  }

  function setToNewCollection(feel) {
    setFeels([...feels, feel])
  }

  function handleOnDeleteFeel(feelToDelete) {
    const newFeels = feels.filter(feel => feel !== feelToDelete)
    setFeels(newFeels)
  }

  return (
    <>
      <div className={classes.collectionHeader}>
        <div className={classes.collectionNameContainer}>
          <CollectionNameInput
            name={collectionName}
            setName={setCollectionName}
            isCreate={true}
          />
          <div className={classes.collectionNameWrapperButton}>
            <CollectionDropdownList
              selectedOption={collectionLocation}
              setSelectedOption={setCollectionLocation}
              collectionLength={collectionLength + 1}
            />
            <PublicationBadge status={'published'} text={'Live Editing'} />
          </div>
        </div>

        <div className={classes.collectionStatusButtons}>
          <ButtonOutline
            color={'green'}
            size="small"
            text={'SAVE'}
            disable={denySaving}
            onClick={() => onSaveCollection()}
          />
        </div>
      </div>

      <div className={classes.collectionCarousel}>
        <DragDropContext
          onDragEnd={handleOnDragEnd}
          onDragStart={handleOnDragStart}
        >
          <Droppable droppableId="feels" direction="horizontal">
            {provided => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                style={{ display: 'flex' }}
              >
                {feels.length > 0 &&
                  feels.map((feel, index) => (
                    <Draggable
                      key={feel.f_id}
                      draggableId={feel.f_id}
                      index={index}
                    >
                      {provided => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <FeelItemCard
                            isDragging={isDragging}
                            feel={feel}
                            isMutedOnHover={feelsMuted}
                            onClick={handleClickOnFeel}
                            onMuteUnmuteClick={() => setFeelsMuted()}
                            onDelete={handleOnDeleteFeel}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {feels.length < feelsLimit && (
          <FeelAddItem
            isDragging={isDragging}
            reservedFeels={getFeelsFIDs(feels)}
            setToNewCollection={setToNewCollection}
          />
        )}
      </div>
    </>
  )
}

CollectionAdd.propTypes = {
  collectionLength: PropTypes.number,
}
