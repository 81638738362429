import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useAuth } from '../Contexts/AuthContext'
import logo from './icons/feelsLogo.png'
import useUserInfo from '../services/hooks/useUserInfo'
import emptyUserIcon from './icons/profileBtn.png'
import './Navbar.css'
import { orgRoles } from '../constants/roles'
import useAccessLevel from '../Pages/Organizations/hooks/useAccessLevel'

export default function Nav() {
  const [toggleProfileDropdown, setToggleProfileDropdown] = useState(false)
  const [profileButtonRect, setProfileButtonRect] = useState()
  const { getCurrentUser, logout } = useAuth()
  const [isDev] = useState(process.env.REACT_APP_IS_DEV)
  const navigate = useNavigate()
  const { icon } = useUserInfo()
  const { isInternalAdmin, isOrganization } = useAccessLevel()

  useEffect(() => {
    const button = document.getElementsByClassName('profile-button')[0]
    setProfileButtonRect(button.getBoundingClientRect())
  }, [])

  async function handleLogout() {
    setToggleProfileDropdown(false)
    try {
      await logout()
    } catch (error) {
      console.log(error)
    }
  }

  function getSidebarWidth() {
    const sidebar = document.getElementById('sidebar')
    return sidebar.getBoundingClientRect().width
  }

  const internalLinks = isInternalAdmin => {
    if (!isInternalAdmin) {
      return null
    }
    return (
      <>
        <Link
          to="/organization"
          className="logout-button"
          onClick={() => setToggleProfileDropdown(false)}
        >
          Feels Team
        </Link>
        <Link
          to="/organizations"
          className="logout-button"
          onClick={() => setToggleProfileDropdown(false)}
        >
          Organizations
        </Link>
      </>
    )
  }

  const groupLinks = isOrganization => {
    if (!isOrganization) {
      return null
    }

    const user = getCurrentUser()
    const organizations = user.organizations
    const groups = organizations
      .reduce(
        (acc, organization) => [
          ...acc,
          ...organization.organization_user.organization_groups,
        ],
        []
      )
      .filter(group => group.name !== 'Organization')

    if (!groups.length) {
      return null
    }
    if (groups.length > 2) {
      return (
        <Link
          to={`/organizations/my-teams`}
          className="logout-button"
          onClick={() => setToggleProfileDropdown(false)}
        >
          My Teams
        </Link>
      )
    }

    const links = organizations.map(organization =>
      organization.organization_user.organization_groups
        .filter(group => group.name !== 'Organization')
        .map(group => {
          return (
            <Link
              key={group.f_id}
              to={`/organizations/${organization.f_id}/teams/${group.f_id}`}
              className="logout-button"
              onClick={() => setToggleProfileDropdown(false)}
            >
              {group.name} <span>team</span>
            </Link>
          )
        })
    )
    return links
  }
  const organizationLinks = isOrganization => {
    if (!isOrganization) {
      return null
    }

    const user = getCurrentUser()
    const organizations = user.organizations
    const links = organizations.map(organization => {
      const groupOrganization =
        organization.organization_user.organization_groups.find(
          group => group.name === 'Organization'
        )

      if (!groupOrganization) return null

      const groupOrganizationUserRole = groupOrganization.roles[0].role
      const hasOrgAccess = orgRoles.includes(groupOrganizationUserRole)

      if (hasOrgAccess) {
        return (
          <Link
            key={organization.f_id}
            to={`/organizations/${organization.f_id}`}
            className="logout-button"
            onClick={() => setToggleProfileDropdown(false)}
          >
            {organization.name}
          </Link>
        )
      }
      return null
    })
    return links
  }

  return (
    <div id="navbar">
      <img
        id="navbar-logo"
        src={logo}
        alt="logo"
        onClick={() => {
          navigate('/artists')
        }}
      />
      <div className="right-container">
        {isDev === 'true' ? (
          <img
            className="label-logo"
            src={require('./icons/dev_logo.png')}
            alt="label logo"
          />
        ) : (
          <span className="your-logo-here-text">
            {/* RIGHTS HOLDER&#39;S LOGO HERE */}
          </span>
        )}
        <button
          className="profile-button"
          style={{
            backgroundImage: `url(${icon || emptyUserIcon})`,
          }}
          referrerPolicy="no-referrer"
          onClick={() => {
            setToggleProfileDropdown(!toggleProfileDropdown)
          }}
        ></button>
        {toggleProfileDropdown && (
          <div
            className="profile-dropdown-bg-container"
            style={{
              top: 0 + 'px',
              // right: -profileButtonRect.left - 300 + 'px',
              left: -getSidebarWidth() + 'px',
              // bottom: 200 + 'px',
            }}
            onClick={e => {
              const dataView =
                document.getElementsByClassName('profile-dropdown')
              const dataViewRect = dataView[0].getBoundingClientRect()
              const contains =
                e.clientX >= dataViewRect.x &&
                e.clientX <= dataViewRect.x + dataViewRect.width &&
                e.clientY >= dataViewRect.y &&
                e.clientY <= dataViewRect.y + dataViewRect.height

              if (!contains) {
                setToggleProfileDropdown(false)
              }
            }}
          >
            <div
              className="profile-dropdown"
              style={{
                top:
                  profileButtonRect.top + profileButtonRect.height + 20 + 'px',
                right:
                  profileButtonRect.left - profileButtonRect.left + 20 + 'px',
              }}
            >
              <p style={{ margin: 10, color: 'white', textAlign: 'center' }}>
                {getCurrentUser()?.email ?? ''}
              </p>
              <div style={{ backgroundColor: 'white', height: 0.5 }} />
              {internalLinks(isInternalAdmin)}
              {organizationLinks(isOrganization)}
              {groupLinks(isOrganization)}
              <button
                className="logout-button"
                onClick={() => {
                  handleLogout()
                }}
              >
                Log Out
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
